import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
      var profilepic= "images/"+this.props.data.image;
      var name = this.props.data.name;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">About</a></li>
	         <li><a className="smoothscroll" href="#resume">Resume</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>
      <div className="tab" id="mytabs">
         <button className="mytablinks">
            <div className=" text-center">
            <div className="scene">
               <a className="smoothscroll" href="#home">
                  <div className="box ">
                     <div id="homeBtn" className=" front face mycurrent">
                        <i id="" className="myinnercontainer center fa fa-home"></i>
                     </div>
                     <div className="center right face ">
                        <p className="pinnercontainer">Home</p>
                     </div>
                  </div>
               </a>
            </div>
            </div>
         </button>
         <button className="mytablinks">
         <div className="text-center">
            <div className="scene">
               <a className="smoothscroll" href="#about">
                  <div className="box ">
                     <div id="aboutBtn" className="front face">
                        <i className="myinnercontainer center fa fa-info"></i>
                     </div>
                     <div className="center right face">
                        <p className="pinnercontainer">About</p>
                     </div>
                  </div>
               </a>
            </div>
         </div>
         </button>
         <button className="mytablinks">
         <div className="text-center">
            <div className="scene">
               <a className="smoothscroll" href="#resume">
                  <div className="box ">
                     <div id="resumeBtn" className="front face">
                        <i className="myinnercontainer center fa fa-book"></i>
                     </div>
                     <div className="center right face">
                        <p className="pinnercontainer">Resume</p>
                     </div>
                  </div>
               </a>
            </div>
         </div>
         </button>
         <button className="mytablinks" >
         <div className="text-center">
            <div className="scene">
               <a className="smoothscroll" href="#contact">
                  <div className="box ">
                     <div id="contactBtn" className="front face">
                        <i className="myinnercontainer center fa fa-envelope-o"></i>
                     </div>
                     <div className="center right face">
                        <p className="pinnercontainer">Contact</p>
                     </div>
                  </div>
               </a>
            </div>
         </div>
         </button>
      </div>
      <div id="" className="row banner background">
         <div className="four columns banner-text">
            <img className="profile-pic"  src={profilepic} alt="Michael Clanton Profile Pic" />
         </div>
         <div className="eight columns main-col">
         <h1 className="responsive-headline">{name}</h1>
            <h3 className="description">Data Engineer | Software Engineer</h3>
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
